// import AOS from 'aos';



jQuery( document ).ready(function($) {





  // // animations

  // AOS.init({

  //   duration: 1000,

  //   once: true,

  //   disable: 'mobile',

  // });

  // let scrollRef = 0

  // window.addEventListener('scroll', () => 0 <= 10 ? scrollRef++ : AOS.refresh() );





  // countdown

  var countDownDate = new Date("Mar 3, 2023 00:00:00").getTime();



  var x = setInterval(function() {

    var now = new Date().getTime()

    var distance = countDownDate - now

    var days = Math.floor(distance / (1000 * 60 * 60 * 24))

    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))

    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))

    var seconds = Math.floor((distance % (1000 * 60)) / 1000)

    document.querySelector("#countdown .hours").innerHTML = (days * 24 + hours)

    document.querySelector("#countdown .minutes").innerHTML = minutes

    document.querySelector("#countdown .seconds").innerHTML = seconds

    if((days * 24 + hours) < 9){

      document.querySelector("#countdown .hours").innerHTML = `0${(days * 24 + hours)}`

    } else{

      document.querySelector("#countdown .hours").innerHTML = (days * 24 + hours)

    }

    if(minutes < 9){

      document.querySelector("#countdown .minutes").innerHTML = "0" + minutes

    } else{

      document.querySelector("#countdown .minutes").innerHTML = minutes

    }

    if(seconds < 9){

      document.querySelector("#countdown .seconds").innerHTML = "0" + seconds

    } else{

      document.querySelector("#countdown .seconds").innerHTML = seconds

    }

    

    if (distance < 0) {

      clearInterval(x);

      document.querySelector("#countdown span").innerHTML = "Available now!"

    }

  }, 1000);





});